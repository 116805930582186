<template>
  <Layout class="GameLibrary">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item prop="userId">
          <el-input
            v-model="formData.userId"
            clearable
            placeholder="UID"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="UID"
        prop="userIdStr"
      />

      <el-table-column
        label="消息内容"
        prop="body"
      >
        <template #default="{row}">
          <div style="margin-left:10px">
            {{ row.body }}
          </div>
          <div
            style="display:flex"
          >
            <img
              v-for="(t,i) of row.param"
              :key="i"
              style="width: 50px;margin-left:10px"
              :src="t"
              alt=""
              @click="checkimg(t)"
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="推送时间（北京时间）"
        prop="createTimeSh"
      />
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryFeedbackListList"
    />
    <el-dialog
      :title="dialogTile"
      :visible.sync="showDialog"
      width="800px"
      style="text-align: center;"
      @close="handleDialogClose"
    >
      <img
        style="max-width: 700px;"
        :src="imgUrl"
        alt=""
      >
    </el-dialog>
  </Layout>
</template>

<script>
// import { format } from 'fecha'
import {
  userMessageList,
  getFeedbackTypes,
  forbiddenFeedbackUser
} from '@/api/Userfeedback.js'
// const getYesterday = () => {
//   return [format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss'), format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss')]
//   // const hours = new Date().getHours()
//   // // 当天上午9点以后, 默认时间查询昨天信息, 否则查询前天
//   // const days = hours > 9 ? 1 : 2
//   // const targetDay = new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
//   // return [format(targetDay, 'YYYY-MM-DD'), format(targetDay, 'YYYY-MM-DD')]
// }
// const getYesterday = () => {
//   return [format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss'), format(new Date().getTime(), 'YYYY-MM-DD HH:mm:ss')]
//   // const hours = new Date().getHours()
//   // // 当天上午9点以后, 默认时间查询昨天信息, 否则查询前天
//   // const days = hours > 9 ? 1 : 2
//   // const targetDay = new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
//   // return [format(targetDay, 'YYYY-MM-DD'), format(targetDay, 'YYYY-MM-DD')]
// }
export default {
  name: 'Messagerecord',

  data () {
    return {
      showDialog: false,
      dialogTile: '查看图片',
      imgUrl: '',
      statisticsLoading: false,
      statisticsInfo: {},
      statusList: [
        { name: 'All', value: 1 },
        { name: 'Lag and frame drop', value: 2 },
        { name: 'Controller/Keyboard&Mouse', value: 3 },
        { name: 'Crash、blank screen', value: 4 },
        { name: 'Faile to recharge', value: 5 },
        { name: 'Others', value: 6 }
      ],
      statusMap: {
        1: '未编辑',
        2: '已编辑'
      },
      formData: {
        dateRange: null,
        pageNum: 1,
        userId: '',
        typeId: '',
        type: '1,2',
        pageSize: 20
      },
      // pickerOptions: {
      //   disabledDate (time) {
      //     const hours = new Date().getHours()
      //     const days = hours > 9 ? 1 : 2
      //     const dateTime = time.getTime()
      //     return dateTime < new Date('2020-12-31').getTime() || dateTime > new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
      //   }
      // },
      pickerOptions: {
        disabledDate (time) {
          // const hours = new Date().getHours()
          // const days = hours > 9 ? 1 : 2- days * 24 * 60 * 60 * 1000
          const dateTime = time.getTime()
          return dateTime < new Date('2020-12-31').getTime() || dateTime > new Date((Date.now())).getTime()
        }
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      newGameVisible: false,
      newGameLoading: false,
      newGameForm: {
        platform: 'steam',
        sourceId: ''
      }
    }
  },
  created () {
    this.handleSearch()
  },
  activated () {
    this.handleSearch()
  },
  methods: {
    checkimg (img) {
      this.imgUrl = img
      this.showDialog = true
    },
    handleDialogClose () {
      this.imgUrl = ''
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryFeedbackListList()
    },
    queryFeedbackListList () {
      if (this.formData.dateRange) {
        const [startTime, endTime] = this.formData.dateRange
        this.formData.timeStart = startTime
        this.formData.timeEnd = endTime
      } else {
        delete this.formData.timeStart
        delete this.formData.timeEnd
      }
      this.listInfo.loading = true
      userMessageList(this.formData)
        .then(res => {
          if (res.code === 200) {
            if (res.data.list) {
              for (var i = 0; i < res.data.list.length; i++) {
                if (res.data.list[i].type === 2) {
                  res.data.list[i].param = JSON.parse(res.data.list[i].param).imgUrls
                } else {
                  res.data.list[i].param = []
                }
              }
            }
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    queryFeedbackTypes () {
      this.listInfo.loading = true
      getFeedbackTypes({})
        .then(res => {
          if (res.code === 200) {
            if (res.data.mapData) {
              this.statusMap = res.data.mapData
              var arr = []
              for (var i in res.data.mapData) {
                arr.push({ name: res.data.mapData[i], value: i })
              }
              this.statusList = arr
            }
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleCreateGame () {
      this.newGameForm.sourceId = ''
      this.newGameVisible = true
    },
    goMessagereply (row) {
      this.$router.push({
        name: 'FeedbackSystemEdit',
        query: {
          userId: row.userIdStr,
          id: row.id
        }
      })
    },
    goEditstatus (row, num) {
      this.listInfo.loading = true
      forbiddenFeedbackUser({ userId: row.userIdStr, status: num, adminId: this.$store.state.user.user.id })
        .then(res => {
          if (res.code === 200) {
            this.queryFeedbackListList()
            if (num === 1) {
              this.$message.success('解禁成功')
            } else {
              this.$message.success('禁用成功')
            }
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less">
  .GameLibrary {
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
  }
</style>
